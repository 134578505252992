import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Env {
  //For local environment
   //LILLY_URL:string='http://localhost:4200/';
   //OSF_BACKEND_URL:string='http://localhost:3000/';
  
   //For dev environment
   //Prospective_URL:string='https://prospectivesupplier-d.lilly.com/';
   //OSF_BACKEND_URL:string='https://gsit-osf-backend-dev.herokuapp.com/';
  
  //For staging environment
   //Prospective_URL:string='https://prospectivesupplier-q.lilly.com/';
   //OSF_BACKEND_URL:string='https://gsit-osf-backend-stg.herokuapp.com/';
   
  //for Production environment
   Prospective_URL:string='https://prospectivesupplier.lilly.com/'; 
   OSF_BACKEND_URL:string='https://gsit-osf-backend.herokuapp.com/';
}